<template>
  <div>
    <c-table
      ref="grid2"
      class="q-mt-md"
      title="개선요청"
      :columns="grid.columns"
      :gridHeight="gridHeight"
      :data="imprs"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
      @linkClick="imprlinkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addImpr" />
        </q-btn-group>
      </template>
    </c-table>
    <c-table
      ref="grid1"
      title="즉시조치"
      :columns="grid2.columns"
      :gridHeight="gridHeight"
      :data="imprImms"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
      @linkClick="immlinkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addImprImm" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-check-impr-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        safetyCheckId: '',
        safetyCheckResultId: '',
        key: '',
        day: '',
        checkStatusCd: '',
        sysApprovalRequestId: '',
        approvalStatusCd: '',
        data: {
          safetyCheckId: '',  // 열화점검 일련 번호
          plantCd: '',  // 사업장 코드
          checkStatusCd: '',  // 계획수립/점검중/점검완료
          lineId: '',  // 라인일련번호
          checkDeptCd: '',  // 점검부서
          checkUserId: '',  // 점검자 ID
          checkMonth: '',  // 점검년월
          processCd: '',
          processName: '',
          sopName: '',
          location: '',
          safetyCheckResultModelList: [], // sheet
          maps: [],
          deletemaps: [],
        },
        disabled: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'impr',
            field: 'impr',
            label: '개선담당',
            align: 'center',
            child: [
              {
                name: 'actionDeptName',
                field: 'actionDeptName',
                label: '개선담당업체',
                align: 'center',
                style: 'width:120px',
                sortable: false,
              },
              {
                name: 'actionUserName',
                field: 'actionUserName',
                label: '개선담당자',
                align: 'center',
                style: 'width:120px',
                sortable: false,
              },
            ]
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'improveCompleteExpectDate',
            field: 'improveCompleteExpectDate',
            label: '개선완료예정일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '개선대책',
            align: 'left',
            style: 'width:350px',
            sortable: false,
          },
          {
            name: 'improveCompleteDate',
            field: 'improveCompleteDate',
            label: '개선완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
        ],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        closeCallback: null,
      },
      colorItems: [],
      requestImprRow: null,
      imprData: null,
      deleteUrl: '',
      searchUrl: '',
      isComplete: false,
      isSave: false,
      isSave2: false,
      detailData: {
        safetyCheckId: '',  // 안전점검 일련 번호
        dayId: '',
        safetyCheckResultDetailId: '',  // 안전점검결과 일련 번호
        checkUserId: '',  // 점검자 ID
        checkUserName: '',
        checkDate: '',  // 점검일
        safetyCheckName: '',  // 점검명
        conforContent: '',  // 부적합사항
        actionContent: '',  // 즉시조치사항
        vendorCd: '',
        processCd: '',
        processName: '',
        sopName: '',
        sopMapId: '',
        location: '',
        checkOpinion: '',  // 점검자의견
        completeFlag: 'N',
        maps: [],
        deletemaps: [],
      },
    };
  },
  computed: {
    gridHeight() {
      let _height = this.contentHeight - 500;
      if (_height <= 300) {
        _height = 300
      }
      return String(_height) + 'px';
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sai.safetycheck.improve.get.url;
      this.getDetailUrl = selectConfig.sai.safetycheck.detail.get.url;

      this.$comm.uploderSetting('IBM_BEFORE').then(_result => {
        this.$comm.uploderSetting('IBM_AFTER').then(_result2 => {
          this.grid2.columns = [
            {
              name: 'impr',
              field: 'impr',
              label: '개선담당',
              align: 'center',
              child: [
                {
                  name: 'actionDeptName',
                  field: 'actionDeptName',
                  label: '조치업체',
                  align: 'center',
                  style: 'width:120px',
                  sortable: false,
                  required: true,
                },
                {
                  name: 'actionUserName',
                  field: 'actionUserName',
                  label: '조치담당자',
                  userId: 'actionUserId',
                  type: 'user',
                  align: 'center',
                  style: 'width:120px',
                  sortable: false,
                  required: true,
                },
              ]
            },
            {
              name: 'ibmTitle',
              field: 'ibmTitle',
              label: '제목',
              align: 'left',
              style: 'width:280px',
              sortable: false,
              type: 'text',
              required: true,
            },
            {
              name: 'actionContents',
              field: 'actionContents',
              label: '조치내용',
              align: 'left',
              style: 'width:400px',
              sortable: false,
              type: 'textarea',
              required: true,
            },
            {
              name: 'actionCompleteDate',
              field: 'actionCompleteDate',
              label: '조치완료일',
              align: 'center',
              style: 'width:120px',
              sortable: false,
              type: 'date',
              required: true,
            },
            {
              name: 'before',
              field: 'before',
              label: '개선 전 사진',
              align: 'center',
              style: 'width:200px',
              type: 'attach',
              taskClassCd: 'IBM_BEFORE',
              keyText: 'sopImprovementId',
              sortable: false,
              uploaderSetting: _result,
            },
            {
              name: 'after',
              field: 'after',
              label: '개선 후 사진',
              align: 'center',
              style: 'width:200px',
              type: 'attach',
              taskClassCd: 'IBM_AFTER',
              keyText: 'sopImprovementId',
              sortable: false,
              uploaderSetting: _result2,
            },
          ];
        })
      })
      this.getDetail();
      this.getDetail2();
    },
    getDetail2() {
      if (this.popupParam.safetyCheckId) {
        this.$http.url = this.getDetailUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          safetyCheckId: this.popupParam.safetyCheckId,
          dayId: this.popupParam.key
        };
        this.$http.request((_result) => {
          if (_result.data) {
            this.detailData = _result.data;
          }
        },);
      }
    },
    getDetail() {
      if (this.popupParam.safetyCheckResultId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.safetyCheckResultId, this.popupParam.key);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data=[]
          if (_result.data && _result.data.length > 0) {
            // 개선요청
            this.grid.data = this.$_.filter(_result.data, { ibmClassCd: 'IC00000001' });
          }
        },);
      } 
    },
    addImprImm() {
      this.popupOptions.title = '즉시조치'; // 즉시조치
      this.popupOptions.param = {
        requestContents: this.popupParam.checkItemName,
        plantCd: this.popupParam.data.plantCd,
        relationTableKey: this.popupParam.safetyCheckResultId+"/"+this.popupParam.key,
        ibmTaskTypeCd: 'ITT0000001',
        sopMapId: this.detailData.sopMapId,
        row: this.detailData,
        ibmTitle: '[' + this.popupParam.day + '] ' + this.detailData.processName + '/' + this.detailData.sopName 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmediate.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprDetailPopup;
    },
    addImpr() {
      this.popupOptions.title = '개선 요청';
      this.popupOptions.param = {
        requestContents: this.popupParam.checkItemName,
        plantCd: this.popupParam.data.plantCd,
        relationTableKey: this.popupParam.safetyCheckResultId+"/"+this.popupParam.key,
        ibmTaskTypeCd: 'ITT0000001',
        sopMapId: this.detailData.sopMapId,
        row: this.detailData,
        ibmTitle: '[' + this.popupParam.day + '] ' + this.detailData.processName + '/' + this.detailData.sopName 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprDetailPopup;
    },
    /* eslint-disable no-unused-vars */
    imprlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '개선 상세';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprDetailPopup;
    },
    closeImprDetailPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
  }
};
</script>
