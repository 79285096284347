var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "grid2",
          staticClass: "q-mt-md",
          attrs: {
            title: "개선요청",
            columns: _vm.grid.columns,
            gridHeight: _vm.gridHeight,
            data: _vm.imprs,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && !_vm.disabled,
          },
          on: { linkClick: _vm.imprlinkClick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          label: "추가",
                          showLoading: false,
                          icon: "add",
                        },
                        on: { btnClicked: _vm.addImpr },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "grid1",
          attrs: {
            title: "즉시조치",
            columns: _vm.grid2.columns,
            gridHeight: _vm.gridHeight,
            data: _vm.imprImms,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && !_vm.disabled,
          },
          on: { linkClick: _vm.immlinkClick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          label: "추가",
                          showLoading: false,
                          icon: "add",
                        },
                        on: { btnClicked: _vm.addImprImm },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }